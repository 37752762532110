import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import { LeadSection, Button } from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Icon } from "../../fragments/Icon";
import { Layout } from "../../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";

export default () => {
  const { t } = useI18next();

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.contact-meta-thank-you")}
        />
        <title>{t("Thank you")}</title>
      </Helmet>
      <LeadSection
        intent="grey"
        padTop
        title={t("Thank you")}
        subtitle={t(
          "Thank your for reaching out to us today. We will make all our possible to get back to you in a timely manner."
        )}
        cta={
          <>
            <Button
              as={GatsbyLink}
              to="/"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("Get back home")}
            </Button>
          </>
        }
      />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
